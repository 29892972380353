import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormHelperText from '@material-ui/core/FormHelperText';
import { toast } from 'react-toastify';
import Datetime from 'react-datetime';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
// @material-ui/core components
import CustomInput from 'components/CustomInput/CustomInput.js';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Assignment from '@material-ui/icons/Assignment';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from 'components/Table/Table.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardHeader from 'components/Card/CardHeader.js';
import Job from './Job';
import AddJobModal from './AddJobModal';
import api from '../../variables/api';
import { handleError } from '../../variables/handleError';
import { disablePastDates, convertMsToHM } from 'variables/utils';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import { read, utils, writeFile } from 'xlsx';
import { events } from 'variables/general';
import { convertToDateFromMS } from '../../variables/time';
import { countries } from '../../variables/user.constants';
// import { convertToIntHour, getJobHours } from "../TimeReports/index";
import { getTimeDifferent } from '../TimeReports/TimeReportDetails';
import { exportevent } from '../../actions/eventAction';

const useStyles = makeStyles(styles);

const UpdateEvent = () => {
  const [event, setEvent] = useState(null);
  const [eventData, setEventData] = useState(null);
  const [eventData2, setEventData2] = useState(null);
  const [uniquejobDates, setuniquejobDates] = useState([]);

  const [loading, setLoading] = useState(false);
  const [filename, setfilename] = useState();
  const [eventid, seteventid] = useState();
  const [companyName, setCompanyName] = useState();
  const [addJobModal, setAddJobModal] = useState({
    isVisible: false,
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();
  const [update, setUpdate] = useState(true);

  const getCountryName = (countryCode) => {
    const newName = countries.find((value) => value.code === countryCode);
    return newName?.name ? newName?.name : countryCode;
  };

  useEffect(() => {
    if (!update) {
      return;
    }
    let isMounted = true;
    (async () => {
      try {
        const eventData1 = [];
        let eventData2 = [];
        let jobdates = [];
        let eid = '';
        setLoading(true);
        const dataEvent = await api.get(`/events/getEvent/${id}`);
        if (isMounted) {
          setEvent(dataEvent.data.data.event);
        }
        const eventdetail = dataEvent.data.data.event;
        setfilename(eventdetail.eventID);
        const e1 = {
          id: eventdetail._id,
          createdAt: eventdetail.createdAt,
          isConfirmed: eventdetail.isConfirmed,
          eventName: eventdetail.eventName,
          eventExpirationDate: moment(
            convertToDateFromMS(eventdetail.eventExpirationDate)
          ).format('DD-MM-YYYY hh:MM:ss'),
          eventID: eventdetail.eventID.substr(eventdetail.eventID.length - 8),
        };
        if (eventdetail.jobs && eventdetail.jobs.length) {
          eventdetail.jobs.map((job) => {
            const j1 = {
              jobid: job._id,
              jobBonus: job.jobBonus,
              jobName: job.jobName,
              jobDescription: job.jobDescription,
              jobRequirements: job.jobRequirements,
              showAsTeaser: job.showAsTeaser,
            };
            if (job.jobPositions && job.jobPositions.length) {
              job.jobPositions.map((position) => {
                const p1 = {
                  ...e1,
                  ...j1,
                  positionid: position._id,
                  jobPlaceAddress:
                    position.jobPlaceAddress.value +
                    ' ' +
                    position.jobPlaceAddress.state +
                    ' ' +
                    position.jobPlaceAddress.zipcode +
                    ' ' +
                    position.jobPlaceAddress.address,
                  Joblocation:
                    position.location.addressComponents +
                    ' ' +
                    position.location.formattedAddress,
                  Place_of_work_Zip_code: position.jobPlaceAddress.zipcode,
                  Place_of_work_city: position.jobPlaceAddress.city
                    ? position.jobPlaceAddress.city
                    : '',
                  place_of_work_country: position.jobPlaceAddress.value
                    ? position.jobPlaceAddress.value.split(',').pop()
                    : '',
                  briefings:
                    position.briefings && position.briefings.length
                      ? position.briefings[0] + ', ' + position.briefings[1]
                      : '',
                  readBriefings:
                    position.readBriefings && position.readBriefings.length
                      ? position.readBriefings[0] +
                        ', ' +
                        position.readBriefings[1]
                      : '',
                  contractSubmited: position.contractSubmited,
                  ispositionConfirmed: position.isConfirmed,
                  jobPlace: position.jobPlace,
                  jobDate: moment(convertToDateFromMS(position.jobDate)).format(
                    'DD.MM.YYYY'
                  ),
                  jobStart: moment(parseInt(position.jobStart)).format('HH:mm'),
                  jobEnd: moment(parseInt(position.jobEnd)).format('HH:mm'),
                  hours_worked: getTimeDifferent(
                    position.confirmJobStart
                      ? position.confirmJobStart
                      : position.jobStart,
                    position.confirmJobEnd
                      ? position.confirmJobEnd
                      : position.jobEnd
                  ),
                  Bookedstatus:
                    position.booked && position.booked.employee
                      ? 'Booked'
                      : 'Vacant',
                  bookedon:
                    position.booked && position.booked.employee
                      ? moment(
                          convertToDateFromMS(position.booked.bookedAt)
                        ).format('DD-MM-YYYY hh:MM')
                      : '',
                  bookeduserfirstname:
                    position.booked && position.booked.employee
                      ? position.booked.employee.name.split(' ')[0]
                      : '',
                  bookeduserlastname:
                    position.booked && position.booked.employee
                      ? position.booked.employee.name.split(' ')[1]
                      : '',
                  addressLineOne:
                    position.booked && position.booked.employee
                      ? position.booked.employee.addressLineOne +
                      ', ' +
                      position.booked.employee.addressLineTwo
                      : '',
                  email:
                    position.booked && position.booked.employee
                      ? position.booked.employee?.email
                      : '',
                  phone:
                    position.booked && position.booked.employee
                      ? position.booked.employee?.phone
                      : '',
                  Gender:
                    position.booked && position.booked.employee
                      ? position.booked.employee.gender
                      : '',
                  clothingSize:
                    position.booked && position.booked.employee
                      ? position.booked.employee.size
                      : '',
                  shoeSize:
                    position.booked && position.booked.employee
                      ? position.booked.employee.shoeSize
                      : '',
                  weight:
                    position.booked && position.booked.employee
                      ? position.booked.employee.weight
                      : '',
                  height:
                    position.booked && position.booked.employee
                      ? position.booked.employee.height
                      : '',
                  Birthdate:
                    position.booked && position.booked.employee
                      ? moment(
                          convertToDateFromMS(
                            position.booked.employee.dateOfBirth
                          )
                        ).format('DD.MM.YYYY')
                      : '',
                  SSN:
                    position.booked && position.booked.employee
                      ? position.booked.employee.ssn
                      : '',
                  Nationality:
                    position.booked && position.booked.employee
                      ? position.booked.employee.nationality
                      : '',
                  Zipcode:
                    position.booked && position.booked.employee
                      ? position.booked.employee.postalCode
                      : '',
                  City:
                    position.booked && position.booked.employee
                      ? position.booked.employee.city
                      : '',
                  Country:
                    position.booked && position.booked.employee
                      ? position.booked.employee.country
                      : '',
                  Bankname_IBAN:
                    position.booked && position.booked.employee && position.bookedStaffDetails
                      ? position.booked.employee.nameOfInstitute +
                        ', ' +
                        position.booked.employee.iban +
                        ', ' +
                        position.booked.employee.bic
                      : '',
                  employment_type: 'fallweise',
                  Expenses_20Per: '',
                  Expenses_10Per: '',
                  Expenses_0Per: '',
                  contractId: position.contractId
                    ? position.contractId.contract.contractID
                    : '',
                  jobPrice: position.jobPrice,
                  jobTravelCost: position.jobTravelCost,
                  allowances: '',
                  other: '',
                  other1: '',
                  hourly_rate:
                    j1.jobBonus && j1.jobBonus
                      ? Number(
                          position.hourlyRate +
                            position.hourlyRate * (j1.jobBonus / 100)
                        ).toFixed(2)
                      : position.hourlyRate,
                  Registration_date: '',
                  Deregistration_date: '',
                  Status: '',
                  jobTotalCost: position.jobTotalCost,
                  contarctsigneddate: position.contarctsigneddate
                    ? moment(
                        convertToDateFromMS(position.contarctsigneddate)
                      ).format('DD-MM-YYYY hh:MM')
                    : '',
                  jobDateinmilli: position.jobDate,
                };
                if (p1.Bookedstatus === 'Booked') {
                  const eventDetails = {
                    // PROJEKT: p1.eventID == eid ? "" : p1.eventID ,
                    Email: p1.email,
                    First_name: p1.bookeduserfirstname,
                    Last_name: p1.bookeduserlastname,
                    Gender: p1.Gender === 'female' ? 'weiblich' : 'männlich',
                    Birthdate: p1.Birthdate,
                    Social_Security_Number: p1.SSN,
                    // Employee_Phone: p1.phone,
                    Nationality: getCountryName(p1.Nationality),
                    Streetline_1: p1.addressLineOne,
                    'Zip-code': p1.Zipcode,
                    City: p1.City,
                    Country: getCountryName(p1.Country),
                    Bankname_IBAN: p1.Bankname_IBAN,
                    Place_of_work_Zip_code: p1.Place_of_work_Zip_code,
                    Place_of_work_city: p1.Place_of_work_city,
                    place_of_work_country: p1.place_of_work_country,
                    employment_type: p1.employment_type,
                    'Expenses_20%': p1.Expenses_20Per,
                    'Expenses_10%': p1.Expenses_10Per,
                    'Expenses_0%': p1.Expenses_0Per,
                    travel_costs: String(p1.jobTravelCost).replace('.', ','),
                    allowances: p1.allowances,
                    other: p1.other,
                    other1: p1.other1,
                    hourly_rate: String(p1.hourly_rate).replace('.', ','),
                    Registration_date: p1.Registration_date,
                    Deregistration_date: p1.Deregistration_date,
                    Status: p1.Status,
                    Date: p1.jobDate,
                    hours_worked: String(p1.hours_worked).replace('.', ','),
                    DateinMilli: p1.jobDateinmilli,
                  };
                  const eventDetails2 = {
                    phoneNumber: p1.phone,
                    clothingSize: p1.clothingSize,
                    shoeSize: p1.shoeSize,
                    height: p1.height,
                    weight: p1.weight,
                    DateinMilli: p1.jobDateinmilli,
                  };
                  eid = p1.eventID;
                  if (!jobdates.includes(eventDetails.Date)) {
                    jobdates.push(eventDetails.Date);
                    jobdates.sort(function (a, b) {
                      a = a.split('.').reverse().join('');
                      b = b.split('.').reverse().join('');
                      return a > b ? 1 : a < b ? -1 : 0;
                    });
                  }
                  eventDetails[eventDetails.Date] = eventDetails.hours_worked;
                  eventData1.push(eventDetails);
                  eventData2.push(eventDetails2);
                }
              });
            }
          });
        }

        eventData1.sort((a, b) => a.DateinMilli - b.DateinMilli);
        eventData2.sort((a, b) => a.DateinMilli - b.DateinMilli);
        eventData2 = eventData2.map(({ DateinMilli, ...rest }) => rest);

        setEventData(eventData1);
        setEventData2(eventData2);
        setuniquejobDates(jobdates);
        setUpdate(false);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleError(error);
      }
    })();
    // Clean-up:
    return () => {
      isMounted = false;
    };
  }, [update]);

  const headings = [
    [
      'Email',
      'Vorname',
      'Nachname',
      'Geschlecht',
      'Geburtsdatum',
      'Sozialversicherungsnummer',
      // "Mobilnummer",
      'Staatsbuergerschaft',
      'Wohnort\nAdresse 1',
      'Wohnort\nPLZ',
      'Wohnort\nOrt',
      'Wohnort\nLand',
      'Bank',
      'Dienstort\nPLZ',
      'Dienstort\nOrt',
      'Dienstort\nLand',
      'Beschäftigungsart',
      'Spesen 20%',
      'Spesen 10%',
      'Spesen 0%',
      'KM Geld',
      'Zulagen',
      'Sonstiges',
      'Sonstiges',
      'Stundensatz',
      'Anmeldedatum',
      'Abmeldedatum',
      'Status',
    ].concat(uniquejobDates),
  ];
  const heading1 = [headings[0].slice().splice(0, 27)];
  const headings2 = [headings[0].slice().splice(27)];
  const headings3 = [
    'Telefonnummer',
    'Kleidergröße',
    'Schuhgröße',
    'Körpergröße',
    'Gewicht',
  ];

  const handleExport = async (fileType) => {
    if (eventData && eventData.length) {
      await Promise.all(
        eventData.map(async (edata) => {
          delete edata['Date'];
          delete edata['hours_worked'];
          delete edata['DateinMilli'];
          return edata;
        })
      );
    }
    await api.post('/events/exportevent', { data: { id: id } });
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, [['PROJEKT']]);
    utils.sheet_add_aoa(
      ws,
      [[event.eventID.substr(event.eventID.length - 8)]],
      { origin: 'A2' }
    );

    utils.sheet_add_aoa(ws, heading1, { origin: 'B2' });
    utils.sheet_add_aoa(ws, headings2, { origin: 'AC1' });
    utils.sheet_add_aoa(ws, [headings3], { origin: 'BA2' });

    ws['!cols'] = [];
    utils.sheet_add_json(ws, eventData, { origin: 'B3', skipHeader: true });
    utils.sheet_add_json(ws, eventData2, { origin: 'BA3', skipHeader: true });

    utils.book_append_sheet(wb, ws, 'Report');
    writeFile(wb, `Event - ${filename}.${fileType}`);
  };

  const formik = useFormik({
    initialValues: {
      eventName: event && event.eventName ? event.eventName : '',
      eventExpirationDate:
        event && event.eventExpirationDate ? event.eventExpirationDate : null,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      eventName: Yup.string().required('Event name is required'),
      eventExpirationDate: Yup.number()
        .required('Event expiration date is required')
        .test(
          'eventMinDate',
          'Provide valid event expiration date',
          (value, fields) => {
            const minDate = moment().startOf('day').valueOf();
            const currDate = parseInt(
              moment(fields.parent.eventExpirationDate).format('x')
            );
            if (currDate < minDate) {
              return false;
            } else {
              return true;
            }
          }
        )
        .test(
          'eventDate',
          'Event expiration date must be before job date',
          (value, fields) => {
            const eventExpirationDate = fields.parent.eventExpirationDate;
            let dates = [];
            event.jobs.map((job) => {
              job.jobPositions.map((position) => dates.push(position.jobDate));
            });
            const minDate = Math.min(...dates);
            const currDate = parseInt(moment(eventExpirationDate).format('x'));
            if (currDate > minDate) {
              return false;
            } else {
              return true;
            }
          }
        ),
    }),
    onSubmit: async (values) => {
      try {
        await api.patch(`/events/updateEvent/${event._id}`, {
          ...values,
        });
        toast.success('Updated event Successfully!');
        window.location.reload();
      } catch (error) {
        handleError(error);
      }
    },
  });

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '35px 15px',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        {alert}
        {addJobModal.isVisible && (
          <AddJobModal
            addJobModal={addJobModal}
            setAddJobModal={setAddJobModal}
            event={event}
            setLoading={setLoading}
            setEvent={setEvent}
          />
        )}
        <div className="go-back">
          <span onClick={(e) => history.push('/admin/events')}>
            <ArrowBackIosIcon />
            <span className="text-in">Go Back</span>
          </span>
        </div>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Event Detail</h4>
          </CardHeader>
          <CardBody>
            <div className="add-job-wrapper">
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={() => handleExport('xlsx')}
              >
                Excel &nbsp;<i className="fa fa-download"></i>
              </Button>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={() => handleExport('csv')}
              >
                CSV &nbsp;<i className="fa fa-download"></i>
              </Button>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <p></p>
              <p>Event ID: {event?.eventID || ''} </p>
              <GridContainer>
                <GridItem xs={4} lg={4}>
                  <CustomInput
                    labelText="Event Name"
                    id={`text${id + 1}`}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'text',
                      name: 'eventName',
                      value: formik.values.eventName,
                      onChange: formik.handleChange,
                      touched: formik.touched.eventName,
                      errors: formik.errors.eventName,
                      onBlur: formik.handleBlur,
                    }}
                  />
                </GridItem>
                <GridItem xs={4} lg={4}>
                  <InputLabel className={classes.label}>
                    Event Expiration Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      isValidDate={disablePastDates}
                      timeFormat={false}
                      dateFormat="DD-MM-YYYY"
                      initialValue={formik.values.eventExpirationDate}
                      inputProps={{ placeholder: 'Date' }}
                      closeOnSelect={true}
                      onChange={(e) => {
                        var d = e._d;
                        const time = new Date(d).valueOf();
                        formik.setFieldValue('eventExpirationDate', time);
                      }}
                    />
                  </FormControl>
                  {formik.touched.eventExpirationDate &&
                  formik.errors.eventExpirationDate ? (
                    <FormHelperText
                      id={'1-text'}
                      style={{
                        color: 'red',
                      }}
                    >
                      {formik.errors.eventExpirationDate}
                    </FormHelperText>
                  ) : null}
                </GridItem>
                <GridItem xs={12} lg={12}>
                  <div className="add-job-wrapper">
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        setAddJobModal({ ...addJobModal, isVisible: true })
                      }
                    >
                      Add Job
                    </Button>
                  </div>
                  {event && event.jobs && event.jobs.length
                    ? event.jobs.map((job, id) => (
                        <Job
                          key={job._id}
                          jobindex={id + 1}
                          job={job}
                          setEventLoading={setLoading}
                          setEvent={setEvent}
                          setUpdate={setUpdate}
                          eventExpirationDate={
                            formik.values.eventExpirationDate
                          }
                        />
                      ))
                    : ''}
                </GridItem>
                <GridItem xs={12} lg={12}>
                  <div className="d-flex justify-content-end btn-wrapper bw-update-employer">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={formik.isSubmitting}
                    >
                      Submit
                    </Button>
                  </div>
                </GridItem>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default UpdateEvent;
